
import ApiCalls from './ApiCalls';
import { TwikeyPaymentLink } from '../Models/Payment/TwikeyPaymentLink';
import { TwikeyPaymentLinkStatusRequest } from '../Models/Payment/TwikeyPaymentLinkStatusRequest';
import { TwikeyPaymentLinkStatus } from '../Models/Payment/TwikeyPaymentLinkStatus';
import { TwikeyIdRequest } from '../Models/Payment/TwikeyIdRequest';
import { PaymentSummary } from '../Models/Payment/PaymentSummary';
import NewPaymentLinkRequest from '../Models/Payment/NewPaymentLinkRequest';

export default class PaymentService {

    public async getPaymentLink(model: NewPaymentLinkRequest): Promise<TwikeyPaymentLink> {

        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + "/api/payment/GetPaymentLink/";

        let apiCalls = new ApiCalls();
        let result = await apiCalls.Post(url, model);
        return result;
    }

    public async getTwikeyId(model: TwikeyIdRequest): Promise<string> {

        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + "/api/payment/getTwikeyId/";

        let apiCalls = new ApiCalls();
        let result = await apiCalls.Post(url, model);
        return result;
    }

    public async getPaymentLinkStatus(model: TwikeyPaymentLinkStatusRequest): Promise<TwikeyPaymentLinkStatus> {

        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + "/api/payment/getPaymentLinkStatus/";

        let apiCalls = new ApiCalls();
        let result = await apiCalls.Post(url, model);
        return result;
    }

    public async getInvestigationPrice(examinationDate: Date): Promise<number> {

        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + '/api/payment/GetInvestigationPrice/';

        let apiCalls = new ApiCalls();
        let result = await apiCalls.Post(url, examinationDate);
        return result;
    }

    public async getPaymentSummary(planningItemId: string | null): Promise<PaymentSummary> {

        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + "/api/payment/GetPaymentSummary/";

        let apiCalls = new ApiCalls();
        let result = await apiCalls.Post(url, planningItemId);
        return result;
    }
}