import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Header } from '../Components/Header';
import { Grid, Paper } from '@mui/material';
import LoadingScreen from '../Components/Loadingscreen';

export default function NotFound() {
    const navigate = useNavigate();
    const [redirect, setRedirect] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => {
            setRedirect(true);
        }, 2000)
    }, []);

    return (
        <>
            {page()}
        </>
    );

    function page() {
        if (redirect) {
            navigate("/");
        } else {
            return (
                <>
                    <Header
                        description="Fout"
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper elevation={3}>
                                <h1>
                                    Pagina niet gevonden
                                </h1>
                                <LoadingScreen />
                            </Paper>
                        </Grid>
                    </Grid>
                </>
            )
        }
    }
}