import { red, purple, pink, blue, teal, green, lime, yellow, orange, brown, grey } from '@mui/material/colors';
import { PlanningModel } from '../Models/Planning/PlanningModel';

export function formatDate(data: Date | null | undefined) {
    if (data === null || data === undefined) {
        return '';
    }
    return (new Date(data)).toLocaleString('nl-BE', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    });
}

export function formatTime(data: Date | null | undefined) {
    if (data === null || data === undefined) {
        return '';
    }
    return (new Date(data)).toLocaleString('nl-BE', {
        hour: "2-digit",
        minute: "2-digit"
    });
}

export function formatColumnDateWithTime(data: string | null | undefined) {
    if (data === null || data === undefined) {
        return '';
    }
    return (new Date(data)).toLocaleString('nl-BE', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit"
    });
}

export function formatColumnDate(data: string | null | undefined) {
    if (data === null || data === undefined) {
        return '';
    }
    return (new Date(data)).toLocaleString('nl-BE', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    });
}

export function displayBoolean(data: boolean | null) {
    if (data === null || data === undefined) {
        return '';
    }
    if (data === true) {
        return "Ja"
    }
    if (data === false) {
        return "Nee"
    }
}

export const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
    list.reduce((previous, currentItem) => {
        const group = getKey(currentItem);
        if (!previous[group]) previous[group] = [];
        previous[group].push(currentItem);
        return previous;
    }, {} as Record<K, T[]>);


export function convertBase64(file: Blob) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            resolve(fileReader.result);
        }
        fileReader.onerror = (error) => {
            reject(error);
        }
    })
}

export function InitMedicalcenterColors(resources: PlanningModel[]): PlanningModel[] {
    let defaultColor = grey[500];

    let colorArray = getColorArray();

    let groupedResources = groupBy(resources, r => r.medicalCenter.name);

    //sort alphabetically by medical center name so colours are displayed in same order.
    let sortedKeysByMedicalcenterName = sortObject(groupedResources);

    let colorIndex = 0;

    for (let key in sortedKeysByMedicalcenterName) {

        let resource = groupedResources[key];

        let selectedColor = colorArray[colorIndex];

        //update all grouped resources with same colour
        resource.forEach(r => {
            r.medicalCenter.color = selectedColor ?? defaultColor
        });

        colorIndex++;
    }

    return resources;
}

function sortObject(obj: any) {
    return Object.keys(obj).sort().reduce(function (result: any, key) {
        result[key] = obj[key];
        return result;
    }, {});
}

function getColorArray(): string[] {
    const color1 = red[500];
    const color2 = blue[500];
    const color3 = purple[500];
    const color4 = yellow[500];
    const color5 = teal[500];
    const color6 = green[500];
    const color7 = lime[500];
    const color8 = pink[500];
    const color9 = orange[500];
    const color10 = brown[500];
    const color11 = red[200];
    const color12 = blue[200];
    const color13 = purple[200];
    const color14 = yellow[200];
    const color15 = teal[200];
    const color16 = green[200];
    const color17 = lime[200];
    const color18 = pink[200];
    const color19 = orange[200];
    const color20 = brown[200];
    const color21 = red[900];
    const color22 = blue[900];
    const color23 = purple[900];
    const color24 = yellow[900];
    const color25 = teal[900];
    const color26 = green[900];
    const color27 = lime[900];
    const color28 = pink[900];
    const color29 = orange[900];
    const color30 = brown[900];
    const color31 = red[100];
    const color32 = blue[100];
    const color33 = purple[100];
    const color34 = yellow[100];
    const color35 = teal[100];
    const color36 = green[100];
    const color37 = lime[100];
    const color38 = pink[100];
    const color39 = orange[100];
    const color40 = brown[100];

    const colorArray = [
        color1, color2, color3, color4, color5, color6, color7, color8, color9, color10,
        color11, color12, color13, color14, color15, color16, color17, color18, color19, color20,
        color21, color22, color23, color24, color25, color26, color27, color28, color29, color30,
        color31, color32, color33, color34, color35, color36, color37, color38, color39, color40
    ];

    return colorArray;
}