import ApiCalls from './ApiCalls';
import { ResourceModel } from '../Models/Planning/ResourceModel';
import { GetPlanningModel } from '../Models/Planning/GetPlanningModel';
import { PlanningModel } from '../Models/Planning/PlanningModel';
import { AppointmentCreateModel } from '../Models/Planning/AppointmentCreateModel';
import { AppointmentCreatedResultModel } from '../Models/Planning/AppointmentCreatedResultModel';
import AppointmentCreateFromPidModel from '../Models/Planning/AppointmentCreateFromPidModel';
import ReplanAppointmentModel from '../Models/Planning/ReplanAppointmentModel';

export default class PlanningService {

    public async GetPlanning(model: GetPlanningModel): Promise<PlanningModel[]> {

        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + "/api/Planning/GetPlanning/";

        let apiCalls = new ApiCalls();
        let result = await apiCalls.Post(url, model);
        return result;
    }

    public async GetResourcesMedicalCenter(): Promise<ResourceModel[]> {

        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + "/api/Planning/GetResourcesMedicalCenter/";

        let apiCalls = new ApiCalls();
        let result = await apiCalls.Get(url);
        return result;
    }

    public async CreateAppointment(model: AppointmentCreateModel): Promise<AppointmentCreatedResultModel> {

        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + "/api/Planning/CreateAppointment/";

        let apiCalls = new ApiCalls();
        let result = await apiCalls.Post(url, model);
        return result;
    }

    public async CreateAppointmentForPlanningItemDetailId(model: AppointmentCreateFromPidModel): Promise<AppointmentCreatedResultModel> {

        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + "/api/Planning/CreateAppointmentForPlanningItemDetailId/";

        let apiCalls = new ApiCalls();
        let result = await apiCalls.Post(url, model);
        return result;
    }

    public async CanChangeReservation(planningItemDetailId: string): Promise<boolean> {

        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + "/api/Planning/CanChangeReservation/" + planningItemDetailId;

        let apiCalls = new ApiCalls();
        let result = await apiCalls.Get(url);
        return result;
    }

    public async ReplanAppointment(model: ReplanAppointmentModel): Promise<AppointmentCreatedResultModel> {

        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + "/api/Planning/ReplanAppointment/";

        let apiCalls = new ApiCalls();
        let result = await apiCalls.Post(url, model);
        return result;
    }

    public async IsPlanningItemDetailActive(planningItemDetailId: string): Promise<boolean> {

        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + "/api/Planning/IsPlanningItemDetailActive/";

        let apiCalls = new ApiCalls();
        let result = await apiCalls.Post(url, planningItemDetailId);
        return result;
    }
}