import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, Button, Grid, Paper } from '@mui/material';
import { Header } from '../../Components/Header';
import dayjs from 'dayjs';
import locale from 'dayjs/locale/nl-be';
import PaymentService from '../../Services/PaymentService';
import LoadingScreen from '../../Components/Loadingscreen';
import NewPaymentLinkRequest from '../../Models/Payment/NewPaymentLinkRequest';

export default function Payment() {
    dayjs.locale(locale);
    const { t, i18n } = useTranslation('common');
    const location = useLocation();

    const states = location.state as any;
    const paymentService = useMemo(() => new PaymentService(), []);
    const planningItemDetailId = states?.planningItemDetailId as string;
    const [loading, setLoading] = useState<boolean>(false);
    const [twikeyError, setTwikeyError] = useState<boolean>(false);

    useEffect(() => {

        // console.log("PlanningItemDetailId");
        // console.log(planningItemDetailId);

        doPayment();

    }, []);

    return (
        <>
            <Header description={t('payment.header')} />

            {loadingScreen()}
            {retryPaymentButton()}
        </>
    );

    function doPayment() {

        setLoading(true);

        let model = {
            title: getTitle(),
            redirectUrl: getUrl(),
            planningItemDetailId: planningItemDetailId
        } as NewPaymentLinkRequest;

        // console.log("doPayment model");
        // console.log(model);

        paymentService.getPaymentLink(model).then((result) => {
            console.log("getPaymentLink result")
            console.log(result);

            if (result !== null) {
                //redirect to payment link
                //window.open(result.url, "_blank");
                window.open(result.url, "_self");
            } else {
                setTwikeyError(true);
                toast.error(t('payment.twikeyerror'));
            }
        }).catch((error) => {
            console.log("error catch")
            toast.error(t('payment.twikeyerror'));
        }).finally(() => {
            setLoading(false);
        });
    }

    function getUrl() {
        let baseUrl = process.env.REACT_APP_ENV_URL;
        let url = baseUrl + `/afterpayment?pid=${planningItemDetailId}&lan=${i18n.language}`;
        return url;
    }

    function getTitle() {
        return t('payment.title');
    }

    function loadingScreen() {
        if (loading) {
            return (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper elevation={3}>
                            <LoadingScreen />
                        </Paper>
                    </Grid>
                </Grid>
            )
        }
    }

    function retryPaymentButton() {
        if (twikeyError) {
            return (
                <Box sx={{
                    flexGrow: 1,
                    margin: 2
                }}>
                    <div style={{ minHeight: 20 }} />

                    <h2>
                        {t('payment.twikeyerror')}
                    </h2>

                    <div style={{ minHeight: 20 }} />

                    <Button
                        color="success"
                        className='mt-3'
                        variant="contained"
                        onClick={() => {
                            setTwikeyError(false);
                            doPayment()
                        }}
                        sx={{ fontSize: 25 }}>{t('afterpayment.payAgain')}</Button>
                </Box>
            )
        }

    }
}