import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import backgroundImage from './Images/samantha-gades-BlIhVfXbi9s-unsplash.jpg'
import { useTranslation } from 'react-i18next';

//Pages
import TermsAndConditions from './Pages/DrivingCertificate/TermsAndConditions';
import DriverCertificateForm from './Pages/DrivingCertificate/DriverCertificateForm';
import SelectTimeslot from './Pages/DrivingCertificate/SelectTimeslot';
import Summary from './Pages/DrivingCertificate/Summary';
import Payment from './Pages/DrivingCertificate/Payment';
import AfterPayment from './Pages/DrivingCertificate/AfterPayment';
import NotFound from './Pages/NotFound';
import CompleteAppointment from './Pages/DrivingCertificate/CompleteAppointment';
import { Box } from '@mui/material';
import useWindowHeight from './Hooks/useWindowHeight';

export default function App() {
  const { windowHeight } = useWindowHeight();
  const { t } = useTranslation('common');

  return (
    <>
      <div id="page-wrap" className="fill-window"
        style={{
          width: "100%",
          overflow: "hidden",
          height: `calc(${windowHeight}px - 35px)`
        }}>
        <Box sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "100%",
          width: "100%",
          overflow: "auto"
        }} >
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <Routes>
                {/* DEFAULT */}
                <Route path="/" element={<TermsAndConditions />} />
                <Route path="/DriverCertificateForm" element={<DriverCertificateForm />} />
                <Route path="/SelectTimeslot" element={<SelectTimeslot />} />
                <Route path="/Summary" element={<Summary />} />
                <Route path="/Payment" element={<Payment />} />
                <Route path="/AfterPayment" element={<AfterPayment />} />
                <Route path="/CompleteAppointment" element={<CompleteAppointment />} />

                <Route path='*' element={<NotFound />} />
              </Routes>
              <ToastContainer
                position="bottom-right"
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
            </BrowserRouter >
          </ThemeProvider>
        </Box>
      </div>
      {footer()}
    </>
  );

  function footer() {
    return (
      <div className="fixed-bottom">
        < div className="d-flex justify-content-center align-items-center background-color-CLBGroup" style={{ fontSize: '10px', minHeight: '35px' }}>
          © {new Date().getFullYear()} -  {t('footer.copyrightText')}
        </div>
      </div>
    )
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#88527a'
    },
    secondary: {
      main: '#d32f2f'
    }
  },
  typography: {
    button: {
      textTransform: 'none'
    }
  }
});