import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Header } from '../../Components/Header';
import { Form, FormGroup } from "reactstrap";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FormControl, InputLabel, MenuItem, OutlinedInput, TextField, Tooltip, Button } from '@mui/material';
import { formatDate } from '../../Components/Helpers';
import DriverService from '../../Services/DriverService';
import { DriverModel } from '../../Models/DriverCertificate/DriverModel';
import { Address } from '../../Models/DriverCertificate/Address';
import LoadingScreen from '../../Components/Loadingscreen';
import { useLocation } from 'react-router-dom';

export default function DriverCertificateForm() {
    const { t, i18n } = useTranslation('common');
    const navigate = useNavigate();
    const location = useLocation();

    const states = location.state as any;
    const planningItemDetailId = states != null ? states.planningItemDetailId as string : null; //wanneer planning is gemaakt via Colibri en moet aangevuld worden.

    const [hideBillingAddress, setHideBillingAddress] = React.useState<boolean>(true);
    const [emailValid, setEmailValid] = React.useState<boolean>(true);
    const [emailValidHelperText, setEmailValidHelperText] = React.useState<string>("");
    const driverService = useMemo(() => new DriverService(), []);
    const [loading, setLoading] = useState<boolean>(false);
    const [homeAddresRequired, setHomeAddresRequired] = useState<boolean>(true);
    const [workAddresRequired, setWorkAddresRequired] = useState<boolean>(false);
    const [rrnInValid, setRrnInValid] = useState<boolean>();

    const [driverModel, setDriverModel] = useState<DriverModel>({
        insz: "",
        birthDate: null,
        firstName: "",
        lastName: "",
        language: "nl",
        emailAddress: "",
        mobilePhoneNumber: "",
        accountName: "",
        vatNbr: "",
        homeAddress: {} as any,
        workAddress: {} as any,
        contactId: null,
        employmentId: null
    });

    const [homeAddress, setHomeAddress] = React.useState<Address>({
        street: "",
        number: "",
        postalCode: "",
        city: "",
        country: ""
    });

    const [workAddress, setWorkAddress] = React.useState<Address>({
        street: "",
        number: "",
        postalCode: "",
        city: "",
        country: ""
    });

    useEffect(() => {
        //update language on drivermodel when clicked on button in top right corner
        i18n.on('languageChanged', () => {
            setDriverModel(prevFile => {
                const copy = { ...prevFile } as DriverModel;
                copy.language = i18n.language;
                return copy;
            });
        });
    }, []);

    return (
        <>
            <Header description={t('driver.header')} />
            {getFormFields()}
        </>
    );

    function getFormFields() {
        if (loading) {
            return (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper elevation={3} className='makeTransparent'>
                            <LoadingScreen />
                        </Paper>
                    </Grid>
                </Grid>
            )
        }

        let buttonText = t('driver.submit');
        if (planningItemDetailId)
            buttonText = t('driver.submitToPayment');

        return (
            <Form onSubmit={submitData}>
                <Box sx={{
                    flexGrow: 1,
                    margin: 1
                }}>
                    <Paper elevation={3} className='makeTransparent'>
                        <div className='d-flex justify-content-end me-2'>{t('driver.intro')}</div>
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <h2 className='p-2'>{t('driver.general')}</h2>
                                <FormGroup className='ms-2 me-2 mt-2 mb-2' >
                                    <Tooltip title={t('driver.inszTooltip')}>
                                        <TextField fullWidth margin="dense" id="insz" label={t('driver.insz')} variant="outlined" name="insz"
                                            value={driverModel.insz} onChange={(event) => handleDriverModelChanged(event)}
                                            error={rrnInValid}
                                            required helperText={rrnInValid ? t('driver.inszHelpText') : ''}
                                        />
                                    </Tooltip>

                                    <TextField fullWidth margin="dense" id="birthDate" label={t('driver.birthdate')} variant="outlined"
                                        name="birthDate" value={formatDate(driverModel.birthDate)}
                                        disabled
                                    />

                                    <TextField fullWidth margin="dense" id="firstname" label={t('driver.firstname')} variant="outlined"
                                        name="firstName" value={driverModel.firstName} onChange={(event) => handleDriverModelChanged(event)}
                                        required />

                                    <TextField fullWidth margin="dense" id="lastName" label={t('driver.name')} variant="outlined"
                                        name="lastName" value={driverModel.lastName} onChange={(event) => handleDriverModelChanged(event)}
                                        required />

                                    <FormControl fullWidth margin="dense">
                                        <InputLabel id="selectlanguage-label">{t('driver.selectlanguage')}</InputLabel>
                                        <Select
                                            style={{ textAlign: "left" }}
                                            labelId="selectlanguage-label"
                                            id="selectlanguage"
                                            value={driverModel.language}
                                            onChange={handleLanguageChangedInForm}
                                            input={<OutlinedInput label="selectlanguage" />}
                                        >
                                            <MenuItem value={""}></MenuItem>
                                            <MenuItem value={"nl"}>{t('driver.language.nl')}</MenuItem>
                                            <MenuItem value={"fr"}>{t('driver.language.fr')}</MenuItem>
                                            <MenuItem value={"de"}>{t('driver.language.de')}</MenuItem>
                                            <MenuItem value={"en"}>{t('driver.language.en')}</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <Tooltip title={t('driver.mailTooltip')}>
                                        <TextField fullWidth margin="dense" id="mail" label={t('driver.mail')} variant="outlined" required
                                            helperText={emailValidHelperText}
                                            error={!emailValid}
                                            name="emailAddress" value={driverModel.emailAddress} onChange={(event) => handleDriverModelChanged(event)} />
                                    </Tooltip>

                                    <Tooltip title={t('driver.mobilephoneTooltip')}>
                                        <TextField fullWidth margin="dense" id="mobilephone" label={t('driver.mobilephone')} variant="outlined" required
                                            name="mobilePhoneNumber" value={driverModel.mobilePhoneNumber} onChange={(event) => handleDriverModelChanged(event)} />
                                    </Tooltip>

                                    <TextField fullWidth margin="dense" id="companyname" label={t('driver.companyname')} variant="outlined"
                                        name="accountName" value={driverModel.accountName} onChange={(event) => handleDriverModelChanged(event)} />

                                    <TextField fullWidth margin="dense" id="vatnbr" label={t('driver.vatnbr')} variant="outlined"
                                        name="vatNbr" value={driverModel.vatNbr} onChange={(event) => handleDriverModelChanged(event)} />

                                </FormGroup>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <h2 className='p-2'>{t('driver.addresstitle')}</h2>
                                <FormGroup className='ms-2 me-2 mt-2 mb-2' >
                                    <TextField fullWidth margin="dense" id="address.street" label={t('driver.address.street')} variant="outlined"
                                        name="street" value={homeAddress?.street} onChange={(event) => handleHomeAddressChanged(event)}
                                        required={homeAddresRequired} />
                                    <TextField fullWidth margin="dense" id="address.number" label={t('driver.address.number')} variant="outlined"
                                        name="number" value={homeAddress?.number} onChange={(event) => handleHomeAddressChanged(event)}
                                        required={homeAddresRequired} />
                                    <TextField fullWidth margin="dense" id="address.postalcode" label={t('driver.address.postalcode')} variant="outlined"
                                        name="postalCode" value={homeAddress?.postalCode} onChange={(event) => handleHomeAddressChanged(event)}
                                        required={homeAddresRequired} />
                                    <TextField fullWidth margin="dense" id="address.city" label={t('driver.address.city')} variant="outlined"
                                        name="city" value={homeAddress?.city} onChange={(event) => handleHomeAddressChanged(event)}
                                        required={homeAddresRequired} />
                                    <TextField fullWidth margin="dense" id="address.country" label={t('driver.address.country')} variant="outlined"
                                        name="country" value={homeAddress?.country} onChange={(event) => handleHomeAddressChanged(event)}
                                        required={homeAddresRequired} />
                                    {/* <FormControlLabel
                                        control={
                                            <Checkbox
                                                onClick={(e) => toggleCheckboxBillingClicked()}
                                                checked={hideBillingAddress}
                                            />}
                                        label={t('driver.sameasbillingaddress')}
                                    /> */}
                                </FormGroup>
                            </Grid>

                            <Grid item xs={12} md={6}
                                hidden={hideBillingAddress}>
                                <h2>{t('driver.billingaddress')}</h2>
                                <FormGroup className='ms-2 me-2 mt-2 mb-2' >
                                    <TextField fullWidth margin="dense" id="billing.address.street" label={t('driver.address.street')} variant="outlined"
                                        name="street" value={workAddress?.street} onChange={(event) => handleWorkAddressChanged(event)}
                                        required={workAddresRequired} />
                                    <TextField fullWidth margin="dense" id="billing.address.number" label={t('driver.address.number')} variant="outlined"
                                        name="number" value={workAddress?.number} onChange={(event) => handleWorkAddressChanged(event)}
                                        required={workAddresRequired} />
                                    <TextField fullWidth margin="dense" id="billing.address.postalcode" label={t('driver.address.postalcode')} variant="outlined"
                                        name="postalCode" value={workAddress?.postalCode} onChange={(event) => handleWorkAddressChanged(event)}
                                        required={workAddresRequired} />
                                    <TextField fullWidth margin="dense" id="billing.address.city" label={t('driver.address.city')} variant="outlined"
                                        name="city" value={workAddress?.city} onChange={(event) => handleWorkAddressChanged(event)}
                                        required={workAddresRequired} />
                                    <TextField fullWidth margin="dense" id="billing.address.country" label={t('driver.address.country')} variant="outlined"
                                        name="country" value={workAddress?.country} onChange={(event) => handleWorkAddressChanged(event)}
                                        required={workAddresRequired} />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} className='d-flex justify-content-center m-3'>
                                <Button variant="contained" type="submit">{buttonText}</Button>
                            </Grid>
                        </Grid >
                    </Paper>
                </Box>
            </Form >
        );
    }

    function submitData(event: React.FormEvent<HTMLFormElement>): void {
        //prevent form reload when clicked
        event.preventDefault()
        setLoading(true);

        if (rrnInValid) {
            toast.error(t('driver.inszHelpText'));
            setLoading(false);
            return;
        }

        if (!emailValid) {
            toast.error(t('driver.mailNotValid'));
            setLoading(false);
            return;
        }

        // console.log(driverModel);
        // console.log(driverModel.birthDate);

        driverModel.homeAddress = homeAddress;
        if (hideBillingAddress) {
            driverModel.workAddress = null;
        } else {
            driverModel.workAddress = workAddress
        }

        driverService.subscribe(driverModel).then((result) => {
            if (result !== undefined) {
                let state = {
                    state: {
                        driverModel: result,
                        planningItemDetailId: planningItemDetailId
                    }
                }

                navigate("/SelectTimeslot", state);
            } else {
                toast.error(t('driver.errorsaving'));
                setLoading(false);
            }

        }).catch((error) => {
            toast.error(error);
            setLoading(false);
        }).finally(() => {
        });
    }

    function handleLanguageChangedInForm(event: SelectChangeEvent) {
        //setLanguage(event.target.value as string);

        setDriverModel(prevFile => {
            const copy = { ...prevFile } as DriverModel;
            copy.language = event.target.value;
            return copy;
        });

        //change the language of the app in the top right corner to the selected language
        i18n.changeLanguage(event.target.value);
    };

    function handleDriverModelChanged(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        console.log(event);

        if (event.target.name === "insz") {

            var regExOnlyNumbers = /[^0-9]/g;
            let result = event.target.value.replaceAll(regExOnlyNumbers, "");
            event.target.value = result;

            validateInszAndSetBirthDate(event.target.value);
        }

        if (event.target.name === "emailAddress") {
            validateEmailAddress(event.target.value)
        }

        setDriverModel(prevFile => {
            const copy = { ...prevFile } as any;
            copy[event.target.name] = event.target.value;
            return copy;
        });
    }

    function handleHomeAddressChanged(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {

        setHomeAddress(prevFile => {
            const copy = { ...prevFile } as any;
            copy[event.target.name] = event.target.value;
            return copy;
        });
    }

    function handleWorkAddressChanged(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {

        setWorkAddress(prevFile => {
            const copy = { ...prevFile } as any;
            copy[event.target.name] = event.target.value;
            return copy;
        });
    }

    function validateInszAndSetBirthDate(rrn: string) {

        // rrn is 11 digits and a numberw
        if (rrn.length !== 11 || isNaN(Number(rrn))) {
            resetBirthDate();
            setRrnInValid(true);
            return;
        }

        //check validity
        let valid = false;
        const birthdateString = rrn.slice(0, 6);
        let year = parseInt(birthdateString.slice(0, 2));

        //mod97 check
        let modFunction = function (nr: any) { return 97 - (nr % 97); };

        let nrToCheck = parseInt(rrn.slice(0, 9));
        let checkDigitString = rrn.slice((rrn.length - 2), rrn.length);
        let checkDigit = parseInt(checkDigitString);

        // first check if valid mod 97
        if (modFunction(nrToCheck) == checkDigit) {
            //valid 1900
            year += 1900;
            valid = true;
        }

        //if not maybe it is a +2k rrn, add 2 in front of the number and check again 
        nrToCheck = parseInt('2' + rrn.slice(0, 9));
        if (modFunction(nrToCheck) == checkDigit) {
            //valid 2000
            year += 2000;
            valid = true;
        }

        if (valid) {

            setRrnInValid(false);

            const monthIndex = parseInt(birthdateString.slice(2, 4)) - 1; // Month 0 is january
            const day = parseInt(birthdateString.slice(4, 6));

            if (monthIndex === -1 || day === 0) {
                //foreign passports that have no birth cert have zero as month or day, ignore this and set no birth date.                
                return;
            }

            //set birth date
            const newDate = new Date(year, monthIndex, day);

            if (newDate.getFullYear() !== year || newDate.getMonth() !== monthIndex || newDate.getDate() !== day) {
                resetBirthDate();
                return;
            }

            setDriverModel(prevFile => {
                const copy = { ...prevFile } as DriverModel;
                copy.birthDate = newDate;
                return copy;
            });

            return;
        }

        //erase birthdate when not valid 
        resetBirthDate();
        setRrnInValid(true);

    }

    function resetBirthDate() {
        setDriverModel(prevFile => {
            const copy = { ...prevFile } as DriverModel;
            copy.birthDate = null;
            return copy;
        });
    }

    function validateEmailAddress(address: string) {
        let result = emailValid;
        if (address === "") {
            result = true;
        } else {
            let regex = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$');
            result = regex.test(address);
        }

        setEmailValid(result);
        if (result === false) {
            let text = t('driver.mailNotValid');
            setEmailValidHelperText(text);
        } else {
            setEmailValidHelperText('');
        }
    }

    function toggleCheckboxBillingClicked() {

        let newHideWorkAddress = !hideBillingAddress

        if (newHideWorkAddress) {
            //enkel home address required;            
            setHomeAddresRequired(true);
            setWorkAddresRequired(false);
        } else {
            //enkel work address required
            setHomeAddresRequired(false);
            setWorkAddresRequired(true);
        }

        setHideBillingAddress(newHideWorkAddress);
    }


}