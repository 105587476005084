
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FormGroup, Grid, Paper, TextField, } from '@mui/material';
import PaymentService from '../Services/PaymentService';
import { PaymentSummary } from '../Models/Payment/PaymentSummary';
import LoadingScreen from './Loadingscreen';
import { formatDate, formatTime } from './Helpers';

export default function SummaryHelper(props: { planningItemDetailId: string | null }) {
    const { t, i18n } = useTranslation('common');

    const paymentService = useMemo(() => new PaymentService(), []);
    const [paymentSummary, setPaymentSummary] = useState<PaymentSummary>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        // console.log("planningItemDetailId");
        // console.log(props.planningItemDetailId);

        loadPlanningItemDetails();
    }, []);

    return (
        <>
            {loadingScreen()}
            {summary()}
        </>
    );

    async function loadPlanningItemDetails() {
        setLoading(true);
        paymentService.getPaymentSummary(props.planningItemDetailId).then((result) => {
            if (result !== null) {
                // console.log("result");
                // console.log(result);
                setPaymentSummary(result);
                setLoading(false);
            }
            setLoading(false);

        }).catch((error) => {
            toast.error(t('completeAppointment.planningError'));
            setLoading(false);

        }).finally(() => {
            setLoading(false);
        });
    }

    function loadingScreen() {
        if (loading) {
            return (
                <LoadingScreen />
            )
        }
    }

    function summary() {
        if (paymentSummary === undefined || paymentSummary.medicalCenter === undefined) { //medicalcenter is undefined when an unknown random planning item id is queried.
            return (<></>);
        }

        if (!loading && paymentSummary) {
            if (paymentSummary.isInactive) {
                return (
                    <Paper elevation={3} sx={{ height: "100%" }} className='makeTransparent'>
                        <h2>{t('completeAppointment.appointment')}</h2>
                        <hr className='m-2' />
                        <div className='mt-5 mb-5'>{t('completeAppointment.cancelled')}</div>
                    </Paper>
                )
            } else {
                return (
                    <Paper elevation={3} sx={{ height: "100%" }} className='makeTransparent'>
                        <h2>{t('completeAppointment.appointment')}</h2>
                        <hr className='m-2' />
                        <FormGroup className='ms-2 me-2 mt-2 mb-2' >
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField fullWidth variant="standard" margin="dense" id="start" label={t('summary.date')}
                                        name="start" value={formatDate(paymentSummary?.start)} InputProps={{ readOnly: true }} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField fullWidth variant="standard" margin="dense" id="hour" label={t('summary.hour')}
                                        name="hour" value={formatTime(paymentSummary?.start) + ' - ' + formatTime(paymentSummary?.end)} InputProps={{ readOnly: true }} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField fullWidth variant="standard" margin="dense" id="location" label={t('summary.location')} name="location"
                                        value={paymentSummary?.medicalCenter?.name + '\n'
                                            + paymentSummary?.medicalCenter?.street + ' ' + paymentSummary?.medicalCenter?.houseNumber + '\n'
                                            + paymentSummary?.medicalCenter?.postalcode + ' ' + paymentSummary?.medicalCenter?.city}
                                        InputProps={{ readOnly: true }} multiline rows={4} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField fullWidth variant="standard" margin="dense" id="location" label={t('summary.price')} name="location"
                                        InputProps={{ readOnly: true }}
                                        multiline rows={4}
                                        inputProps={{ style: { fontWeight: 'bold' } }}
                                        value={paymentSummary?.price + ' ' + t('summary.valuta')} />
                                </Grid>
                            </Grid>
                            <a className='link'
                                target="_blank"
                                href="https://www.clbgroup.be/media/5348/eigen-verklaring-voor-de-kandidaat-voor-het-rijbewijs-van-groep-2-nl.pdf"
                                rel="noreferrer"
                            >{t('summary.downloadmedicalquestionnare')}</a>
                        </FormGroup>
                    </Paper>
                )
            }
        }
    }
}