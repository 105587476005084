import { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, FormGroup, Grid, Paper } from '@mui/material';
import Box from '@mui/material/Box';
import { Header } from '../../Components/Header';
import PlanningService from '../../Services/PlanningService';
import { PlanningModel } from '../../Models/Planning/PlanningModel';
import { formatDate, formatTime } from '../../Components/Helpers';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PaymentService from '../../Services/PaymentService';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import locale from 'dayjs/locale/nl-be';
import { DriverModel } from '../../Models/DriverCertificate/DriverModel';
import { AppointmentCreateModel } from '../../Models/Planning/AppointmentCreateModel';
import LoadingScreen from '../../Components/Loadingscreen';
import SummaryHelper from '../../Components/SummaryHelper';
import AppointmentCreateFromPidModel from '../../Models/Planning/AppointmentCreateFromPidModel';
import ReplanAppointmentModel from '../../Models/Planning/ReplanAppointmentModel';

export default function Summary() {
    dayjs.locale(locale);
    const { t, i18n } = useTranslation('common');
    const location = useLocation();
    const navigate = useNavigate();

    const states = location.state as any;
    //console.log(states);

    const driverModel = states.driverModel as DriverModel;
    const planningModel = states.planningModel as PlanningModel;
    const planningItemDetailId = states.planningItemDetailId as string;
    const replanPlanningItemDetailId = states.replanPlanningItemDetailId as string;

    const planningService = useMemo(() => new PlanningService(), []);
    const paymentService = useMemo(() => new PaymentService(), []);
    const [loading, setLoading] = useState<boolean>(false);
    const [timeslotError, setTimeslotError] = useState<boolean>(false);
    const [succeeded, setSucceeded] = useState<boolean>(false);
    const [price, setPrice] = useState<number>();

    useEffect(() => {
        // console.log("DriverModel");
        // console.log(driverModel);
        // console.log("PlanningModel");
        // console.log(planningModel);
        // console.log("planningItemDetailId");
        // console.log(planningItemDetailId);
        // console.log("replanPlanningItemDetailId");
        // console.log(replanPlanningItemDetailId);

        loadPrice();

    }, []);

    return (
        <>
            <Header description={t('summary.header')} />
            {GetForm()}
        </>
    );

    function GetForm() {

        if (loading) {
            return (
                <LoadingScreen />
            )
        } else {
            return (
                <>
                    <Box sx={{ flexGrow: 1, margin: 1 }}>
                        <Grid container spacing={1}>
                            {messageBar()}
                            {reservation()}
                            {yourData()}
                            {MessageConcerningPayment()}

                        </Grid>
                    </Box>
                </>

            )
        }
    }

    function messageBar() {
        if (timeslotError) {
            return (
                <Grid item xs={12}>
                    <Paper className="redPaper">
                        <h5><ErrorOutlineIcon
                            fontSize="large"
                            className="mr-2" />
                            {t('summary.timeslotistakenmessage')}
                        </h5>
                    </Paper>
                </Grid>
            )
        }
        if (price === null) {
            return (
                <Grid item xs={12}>
                    <Paper className="redPaper">
                        <h5><ErrorOutlineIcon
                            fontSize="large"
                            className="mr-2" />
                            {t('payment.getpriceerror')}
                        </h5>
                    </Paper>
                </Grid>
            )
        }
        if (succeeded) {
            return (
                <Grid item xs={12}>
                    <Paper className="greenPaper">
                        <h5><CheckCircleOutlineIcon
                            fontSize="large"
                            className="mr-2" />
                            {t('summary.success')}
                        </h5>
                    </Paper>
                </Grid>
            )
        }
    }

    function reservation() {
        if (planningItemDetailId != null) {
            return (
                <Grid item xs={12} md={6}>
                    <SummaryHelper
                        planningItemDetailId={planningItemDetailId} />
                </Grid>
            )
        }
        if (planningModel != null) {
            let size = replanPlanningItemDetailId !== undefined ? 12 : 6;
            return (
                <Grid item xs={12} md={size}>
                    <Paper elevation={3} sx={{ height: "100%" }} className='makeTransparent'>
                        <h2>{t('summary.yourreservation')}</h2>
                        <hr className='m-2' />
                        <FormGroup className='ms-2 me-2 mt-2 mb-2' >
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField fullWidth variant="standard" margin="dense" id="start" label={t('summary.date')}
                                        name="start" value={formatDate(planningModel.start)} InputProps={{ readOnly: true }} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField fullWidth variant="standard" margin="dense" id="hour" label={t('summary.hour')}
                                        name="hour" value={formatTime(planningModel.start) + ' - ' + formatTime(planningModel.end)} InputProps={{ readOnly: true }} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField fullWidth variant="standard" margin="dense" id="location" label={t('summary.location')} name="location"
                                        value={planningModel.medicalCenter.name + '\n'
                                            + planningModel.medicalCenter.street + ' ' + planningModel.medicalCenter.houseNumber + '\n'
                                            + planningModel.medicalCenter.postalcode + ' ' + planningModel.medicalCenter.city}
                                        InputProps={{ readOnly: true }} multiline rows={4} />
                                </Grid>
                                {getPrice()}
                            </Grid>
                            <a
                                className='link'
                                target="_blank"
                                href="https://www.clbgroup.be/media/5348/eigen-verklaring-voor-de-kandidaat-voor-het-rijbewijs-van-groep-2-nl.pdf"
                                rel="noreferrer"
                            >{t('summary.downloadmedicalquestionnare')}</a>
                        </FormGroup>
                        {changeReservationButton()}
                    </Paper>
                </Grid>
            )
        }
    }

    function getPrice() {
        if (price === undefined) {
            return (
                <Grid item xs={6}>
                    <LoadingScreen />
                </Grid>
            )
        }
        else {
            return (
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        variant="standard"
                        margin="dense" id="location"
                        label={t('summary.price')}
                        name="location"
                        InputProps={{ readOnly: true }}
                        multiline rows={4}
                        inputProps={{ style: { fontWeight: 'bold' } }}
                        value={price + ' ' + t('summary.valuta')} />
                </Grid>
            )
        }
    }

    function changeReservationButton() {
        if (!succeeded) {
            return (
                <Button className='mb-3 mt-3' variant="contained" onClick={() => changeReservation()}>{t('summary.change')}</Button>
            )
        }
    }

    function yourData() {
        if (driverModel) {
            return (
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} sx={{ height: "100%" }} className='makeTransparent'>
                        <h2>{t('summary.yourdata')}</h2>
                        <hr className='m-2' />
                        <FormGroup className='ms-2 me-2 mt-2 mb-2' >
                            <TextField fullWidth variant="standard" margin="dense" id="date" label={t('summary.name')}
                                name="name" value={driverModel.firstName + ' ' + driverModel.lastName} InputProps={{ readOnly: true }} />

                            <TextField fullWidth variant="standard" margin="dense" id="email" label={t('summary.email')}
                                name="email" value={driverModel.emailAddress} InputProps={{ readOnly: true }} />

                            <TextField fullWidth variant="standard" margin="dense" id="insz" label={t('summary.insz')}
                                name="insz" value={driverModel.insz} InputProps={{ readOnly: true }} />

                            <TextField fullWidth variant="standard" margin="dense" id="mobilephone" label={t('summary.mobilephone')}
                                name="mobilephone" value={driverModel.mobilePhoneNumber} InputProps={{ readOnly: true }} />
                        </FormGroup>
                    </Paper>
                </Grid>
            )
        }
    }

    function MessageConcerningPayment() {
        if (!timeslotError && replanPlanningItemDetailId === undefined) {
            return (
                <>
                    <Grid item xs={0} md={2}>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Paper elevation={3} className='makeTransparent'>
                            <h4 className='p-3'>
                                {t('summary.paymentmessage')}
                            </h4>

                            <div className='m-2'>
                                {t('summary.paymentmessage2')}
                            </div>
                            <div className='m-2'>
                                {t('summary.paymentmessage3')}
                            </div>
                            <div className='m-2 text-danger fw-bold'>
                                {t('summary.cancellationmessage')}
                            </div>
                            <div className='p-3'>
                                {BottomButtons()}
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={0} md={2}>
                    </Grid>
                </>
            )
        }
    }

    function BottomButtons() {
        if (timeslotError || price === null || price === undefined) {
            return (<></>);
        }

        let submitButtonText = 'summary.submit';
        if (replanPlanningItemDetailId)
            submitButtonText = 'summary.replan';

        return (
            <Button color="success" className='mt-3' variant="contained" onClick={() => submitData()} sx={{ fontSize: 25 }}>{t(submitButtonText)}</Button>
        )

    }

    function submitData() {
        if (planningItemDetailId !== undefined) {
            createAppointmentFromPlanningItemDetailId()
        } else if (replanPlanningItemDetailId !== undefined) {
            replanAppointment();
        } else {
            createNewAppointment();
        }
    }

    function GoToPayment(planningItemDetailId: string) {
        let state = {
            state: {
                planningItemDetailId: planningItemDetailId
            }
        }
        // console.log("state");
        // console.log(state);
        navigate("/Payment", state);
    }

    function changeReservation() {
        //console.log("ChangeReservation");

        let state = {
            state: {
                driverModel: driverModel,
                planningModel: planningModel,
                replanPlanningItemDetailId: replanPlanningItemDetailId
            }
        }

        navigate("/SelectTimeslot", state);
    }

    function loadPrice() {
        //console.log("loadPrice");
        if (price === undefined) {
            paymentService.getInvestigationPrice(planningModel?.start).then((result) => {
                if (result === null) {
                    toast.error(t('payment.getpriceerror'));
                }

                setPrice(result);
            }).catch((error) => {
                toast.error(t('payment.getpriceerror'));
            }).finally(() => {
            });
        }
    }

    function createNewAppointment() {
        let model = {
            start: planningModel.start,
            end: planningModel.end,
            contactId: driverModel.contactId,
            employmentId: driverModel.employmentId,
            planningItemId: planningModel.planningItemId
        } as AppointmentCreateModel;

        setLoading(true);
        planningService.CreateAppointment(model).then((result) => {
            if (result.created === true) {
                setSucceeded(true);
                setTimeslotError(false);
                // console.log("planningItemDetailId");
                // console.log(result.planningItemDetailId);
                GoToPayment(result.planningItemDetailId);
            } else {
                toast.error(t('summary.timeslotistaken'))
                setTimeslotError(true);
                setSucceeded(false);
            }
        }).catch((error) => {
            toast.error(t('summary.errorsaving'));
        }).finally(() => {
            setLoading(false);
        });
    }


    function createAppointmentFromPlanningItemDetailId() {
        let model = {
            planningItemDetailId: planningItemDetailId,
            contactId: driverModel.contactId,
            employmentId: driverModel.employmentId
        } as AppointmentCreateFromPidModel;

        setLoading(true);
        planningService.CreateAppointmentForPlanningItemDetailId(model).then((result) => {
            if (result.created === true) {
                setSucceeded(true);
                setTimeslotError(false);
                // console.log("planningItemDetailId");
                // console.log(result.planningItemDetailId);
                GoToPayment(result.planningItemDetailId);
            } else {
                toast.error(t('summary.timeslotistaken'))
                setTimeslotError(true);
                setSucceeded(false);
            }
        }).catch((error) => {
            toast.error(t('summary.errorsaving'));
        }).finally(() => {
            setLoading(false);
        });
    }

    function replanAppointment() {
        let model = {
            start: planningModel.start,
            end: planningModel.end,
            planningItemId: planningModel.planningItemId,
            replanPlanningItemDetailId: replanPlanningItemDetailId
        } as ReplanAppointmentModel;

        setLoading(true);
        planningService.ReplanAppointment(model).then((result) => {
            // console.log("ReplanAppointment result");
            // console.log(result);

            if (result.created === true) {

                setSucceeded(true);
                setTimeslotError(false);
                let baseUrl = process.env.REACT_APP_ENV_URL;
                let url = baseUrl + `/afterpayment?pid=${result.planningItemDetailId}&lan=${i18n.language}`;
                window.open(url, "_self");

            } else {
                toast.error(t('summary.timeslotistaken'))
                setTimeslotError(true);
                setSucceeded(false);
            }
        }).catch((error) => {
            toast.error(t('summary.errorsaving'));
        }).finally(() => {
            setLoading(false);
        });
    }
}