import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { Header } from '../../Components/Header';
import dayjs from 'dayjs';
import locale from 'dayjs/locale/nl-be';
import { useNavigate } from 'react-router-dom';
import SummaryHelper from '../../Components/SummaryHelper';

export default function CompleteAppointment() {
    dayjs.locale(locale);
    const { t, i18n } = useTranslation('common');
    const navigate = useNavigate();

    const queryParameters = new URLSearchParams(window.location.search)
    const planningItemDetailId = queryParameters.get("pid");

    useEffect(() => {
        // console.log("planningItemDetailId");
        // console.log(planningItemDetailId);

    }, []);

    return (
        <>
            <Header description={t('completeAppointment.header')} />
            <Box sx={{
                flexGrow: 1,
                margin: 1
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {summary()}
                        {buttonToForm()}
                    </Grid>
                </Grid >
            </Box>
        </>
    );

    function summary() {
        return (
            <Grid container spacing={2}>
                <Grid item xs={0} md={3} />
                <Grid item xs={12} md={6}>
                    <SummaryHelper
                        planningItemDetailId={planningItemDetailId} />
                </Grid >
                <Grid item xs={0} md={3} />
            </Grid >
        )
    }

    function buttonToForm() {
        return (
            <div className='p-2'>
                <Button variant="contained" onClick={() => goToDriverCertificateForm()}>{t('completeAppointment.start')}</Button >
            </div>
        )
    }

    function goToDriverCertificateForm() {
        let state = {
            state: {
                planningItemDetailId: planningItemDetailId
            }
        }
        navigate("/DriverCertificateForm", state);
    }
}