import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppBar, Button, Dialog, Grid, Paper, Slide, Toolbar, Typography } from '@mui/material';
import { Header } from '../../Components/Header';
import React from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { Terms } from '../../Models/DriverCertificate/Terms';
import CloseIcon from '@mui/icons-material/Close';
import clbLogo from '../../Images/CLBEdpbLogo.png'


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function TermsAndConditions() {
    const { t } = useTranslation('common');
    const navigate = useNavigate();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [terms, setTerms] = React.useState(Terms.AllConditions);

    useEffect(() => {

    }, []);

    return (
        <>
            <Header
                description='Rijgeschiktheid'
            />
            <div className="text-center m-3">
                <Grid container spacing={2}>
                    <Grid item md={2}>
                    </Grid>
                    <Grid item md={8}>
                        <Paper elevation={3} className='makeTransparent'>
                            <div className='p-2'>
                                <div className='m-3'>
                                    {t('driverterms.pretext')}
                                </div>
                                <div className='m-3'>
                                    <h3>
                                        {t('driverterms.title')}
                                    </h3>
                                </div>
                                <div className='m-3'>
                                    <Button variant="contained" onClick={() => navigate("/DriverCertificateForm")}>{t('driverterms.accept')}</Button >
                                </div>
                                <div className='m-3'>
                                    <h5>
                                        {t('driverterms.subtitle')}
                                    </h5>
                                </div>
                                <div className='m-3'>
                                    {t('driverterms.comment')}
                                </div>
                            </div>
                            <div
                                style={{ fontSize: 15, fontStyle: 'italic' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <div className='m-3' onClick={() => showTerms(Terms.AllConditions)}>
                                            <a className='link' href="#" >
                                                {t('driverterms.conditions')}
                                            </a>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div className='m-3' onClick={() => showTerms(Terms.Privacy)}>
                                            <a className='link' href="#">
                                                {t('driverterms.privacy')}
                                            </a>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div className='m-3' onClick={() => showTerms(Terms.Cancellation)}>
                                            <a className='link' href="#">
                                                {t('driverterms.cancellation')}
                                            </a>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Paper>
                        <Grid item sm={12}>
                        </Grid>
                    </Grid>
                </Grid>
            </div >

            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography style={{ flex: 1 }}>
                        </Typography>
                        <Button
                            color="inherit"
                            onClick={handleClose}>
                            <CloseIcon />
                        </Button>
                    </Toolbar>
                </AppBar>


                {terms === Terms.AllConditions &&
                    <div className="m-3">
                        <div className='m-3'>
                            <h3>1. {t('driverterms.terms1title')}</h3>
                            <div>
                                {t('driverterms.terms1')}
                            </div>
                        </div>
                        <div className='m-3'>
                            <h3>2. {t('driverterms.terms2title')}</h3>
                            <div>
                                {t('driverterms.terms2')}
                            </div>
                        </div>
                        <div className='m-3'>
                            <h3>3. {t('driverterms.terms3title')}</h3>
                            <div>
                                {t('driverterms.terms3')}
                            </div>
                        </div>
                        <div className='m-3'>
                            <h3>4. {t('driverterms.terms4title')}</h3>
                            <div>
                                {t('driverterms.terms4')}
                            </div>
                        </div>
                        <div className='m-3'>
                            <h3>5. {t('driverterms.terms5title')}</h3>
                            <div>
                                {t('driverterms.terms5')}
                            </div>
                        </div>
                        <div className='m-3'>
                            <h3>6. {t('driverterms.terms6title')}</h3>
                            <div>
                                {t('driverterms.terms6')}
                            </div>
                        </div>
                        <div className='m-3'>
                            <h3>7. {t('driverterms.terms7title')}</h3>
                            <div>
                                {t('driverterms.terms7')}
                            </div>
                        </div>
                        <div className='m-3'>
                            <h3>8. {t('driverterms.terms8title')}</h3>
                            <div>
                                {t('driverterms.terms8')}
                            </div>
                        </div>
                    </div>
                }

                {terms === Terms.Privacy &&
                    <div className="m-3">
                        <h3>{t('driverterms.terms5title')}</h3>
                        <div>
                            {t('driverterms.terms5')}
                        </div>
                    </div>
                }

                {terms === Terms.Cancellation &&
                    <div className="m-3">
                        <h3>{t('driverterms.terms4title')}</h3>
                        <div>
                            {t('driverterms.terms4')}
                        </div>
                    </div>
                }

                <div className="text-center m-3">
                    <Button
                        className="m-2"
                        variant="contained"
                        onClick={handleClose}
                    >
                        {t('driverterms.close')}
                    </Button>
                </div>


            </Dialog >

            <div style={{ minHeight: "60px" }} />
            <div className="d-flex justify-content-center m-5 fixed-bottom">
                <img className='ms-3 mb-1' src={clbLogo} alt="CLB Group" style={{ width: "5.5em", height: "3em", }} />
            </div>
        </>
    );

    function showTerms(terms: Terms) {

        setTerms(terms);
        handleOpen();
    }
}

