import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

//styling
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// Translations
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import nl from "./Translations/nl.json";
import fr from "./Translations/fr.json";
import de from "./Translations/de.json";
import en from "./Translations/en.json";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'nl',                              // language to use
  resources: {
    nl: {
      common: nl
    },
    fr: {
      common: fr
    },
    de: {
      common: de
    },
    en: {
      common: en
    },
  },
});

root.render(
  // <React.StrictMode> // Disabled because renders every page twice
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
