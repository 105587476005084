import { Box, CircularProgress } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import LoadingScreen from './Loadingscreen';

export function Counter(props: { pollingFunction: Function, stopFunction: Function, errorMessage: string }) {

    const [count, setCount] = useState(0);

    useInterval(() => {
        //console.log("Retry count: " + count);
        setCount(count + 1);
    }, 3000);//number of miliseconds between ticks

    if (count > 20) // meer dan 20 pogingen, stop met pollen.. 
    {
        toast.error(props.errorMessage)
        props.stopFunction();
    }
    props.pollingFunction();

    return (
        <>
            <LoadingScreen />
        </>
    )
}

export function useInterval(callback: Function, delay: number) {
    const savedCallback = useRef<any>(null);

    useEffect(() => {
        savedCallback.current = callback;
    });

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        let id = setInterval(tick, delay);
        return () => clearInterval(id);
    }, [delay]);
}
