import ClbLogo from '../Images/ClbGroupLogo.png';
import { Col, Row } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select } from '@mui/material';

interface Props {
    description: string;
}

export function Header(props: Props) {
    const { t, i18n } = useTranslation('common');
    const navigate = useNavigate();
    let environmentLabel = process.env.REACT_APP_ENV_NAME;
    //console.log("header init")

    return (
        <div className="background-color-CLBGroup">
            {getContent()}
        </div>
    );

    function getContent() {
        return (
            <div className="d-flex">
                <div className="me-auto p-2">
                    {GetDescriptionOrLogo()}
                </div>
                {GetEnvironment()}
                <div className="p-2">
                    <Select
                        size='small'
                        labelId="language-selector"
                        id="language"
                        value={i18n.language}
                        label="Language"
                        onChange={(event) => i18n.changeLanguage(event.target.value)}
                        variant='outlined'
                        sx={{
                            fontSize: '13px',
                            borderRadius: '15px',
                            color: 'white',
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'white'
                            }
                        }}
                    >
                        <MenuItem value={'nl'}>NL</MenuItem>
                        <MenuItem value={'fr'}>FR</MenuItem>
                        <MenuItem value={'de'}>DE</MenuItem>
                        <MenuItem value={'en'}>EN</MenuItem>
                    </Select>
                </div>
            </div>
        )
    }

    function GetDescriptionOrLogo() {
        if (props.description !== '') {
            return (
                <div style={{ fontSize: "25px" }} className='ms-3'>
                    {props.description}
                </div>
            )
        } else {
            // return (
            //     <img src={ClbLogo} alt="CLB Group" style={{ width: "6em", height: "2.3em" }} className='ms-3' />
            // )
        }
    }

    function GetEnvironment() {
        //environmentLabel = "";
        if (environmentLabel !== "" && environmentLabel !== undefined)
            return (
                <div className="p-1 flex-fill">
                    <b>
                        {environmentLabel}
                    </b>
                </div>
            )
    }
}