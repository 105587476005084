
import ApiCalls from './ApiCalls';
import { DriverModel } from '../Models/DriverCertificate/DriverModel';

export default class DriverService {

    public async subscribe(model: DriverModel): Promise<DriverModel> {

        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + "/api/driver/subscribe/";

        let apiCalls = new ApiCalls();
        let result = await apiCalls.Post(url, model);
        return result;
    }
}