
import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, Grid, Paper, } from '@mui/material';
import Box from '@mui/material/Box';
import { Header } from '../../Components/Header';
import dayjs from 'dayjs';
import locale from 'dayjs/locale/nl-be';
import PaymentService from '../../Services/PaymentService';
import { Counter } from '../../Components/Counter';
import { TwikeyPaymentLinkStatusRequest } from '../../Models/Payment/TwikeyPaymentLinkStatusRequest';
import { TwikeyPaymentLinkStatus } from '../../Models/Payment/TwikeyPaymentLinkStatus';
import { TwikeyIdRequest } from '../../Models/Payment/TwikeyIdRequest';
import { TwikeyState } from '../../Models/Payment/TwikeyState';
import SummaryHelper from '../../Components/SummaryHelper';
import LoadingScreen from '../../Components/Loadingscreen';
import PlanningService from '../../Services/PlanningService';

export default function AfterPayment() {
    dayjs.locale(locale);
    const { t, i18n } = useTranslation('common');
    const navigate = useNavigate();

    // Parameters
    const queryParameters = new URLSearchParams(window.location.search)
    const planningItemDetailId = queryParameters.get("pid");
    const lan = queryParameters.get("lan");

    //Services
    const paymentService = useMemo(() => new PaymentService(), []);
    const planningService = useMemo(() => new PlanningService(), []);

    const testTwikeyResult = {
        amount: 100,
        id: 1,
        msg: "",
        state: TwikeyState.Declined,
        ref: ""
    } as TwikeyPaymentLinkStatus;

    //Variables
    const [loading, setLoading] = useState<boolean>(false);
    const [testMode] = useState<boolean>(false);
    const [resultReceivedFromTwikey, setResultReceivedFromTwikey] = useState<boolean>(false);
    const [twikeyResult, setTwikeyResult] = useState<TwikeyPaymentLinkStatus>();
    const [twikeyId, setTwikeyId] = useState<string>();
    const [canChangeReservation, setCanChangeReservation] = useState<boolean>(false);
    const [planningItemDetailIsActive, setPlanningItemDetailIsActive] = useState<boolean>(false);


    useEffect(() => {
        // console.log("pid");
        // console.log(planningItemDetailId);
        // console.log("lan");
        // console.log(lan);

        if (lan) {
            i18n.changeLanguage(lan);
        }

        getPlanningItemDetailIsActive()
        getTwikeyId();
        getCanChangeReservation();

    }, []);

    return (
        <>
            <Header description={t('afterpayment.header')} />
            <Box sx={{
                flexGrow: 1,
                margin: 1
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {loadingScreen()}

                        {summary()}

                        {lowerBox()}

                        {testmode()}
                    </Grid>
                </Grid >
            </Box>
        </>
    );

    function lowerBox() {
        return (
            <Box sx={{
                flexGrow: 1,
                margin: 1
            }}>
                <Grid container spacing={2} className=''>
                    <Grid item xs={0} md={3}>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} className='mt-2 mb-2 makeTransparent'>

                            {resultMessageFromTwikey()}

                            {retryPaymentButton()}

                            {messageConfirmationMail()}

                            {changeReservationButton()}

                        </Paper>
                    </Grid>
                    <Grid item xs={0} md={3}>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    function loadingScreen() {
        if (loading) {
            return (
                <LoadingScreen />
            )
        }
    }

    function summary() {
        return (
            <Box sx={{
                flexGrow: 1,
                margin: 1,
                marginBottom: 2
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={0} md={3} />
                    <Grid item xs={12} md={6}>
                        <SummaryHelper
                            planningItemDetailId={planningItemDetailId} />
                    </Grid >
                    <Grid item xs={0} md={3} />
                </Grid >
            </Box>
        )
    }

    function resultMessageFromTwikey() {
        if (planningItemDetailIsActive) {
            let color = getResultMessageColor();
            return (
                <>
                    < Grid container spacing={0}>
                        <Grid item xs={0} md={3}>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper
                                className={color}
                                elevation={3}
                                sx={{ margin: 1 }}
                            >
                                <h2>{getResultMessage()}</h2>
                                {loadTwikeyResult()}
                            </Paper>
                        </Grid>
                        <Grid item xs={0} md={3}>
                        </Grid>
                    </Grid>
                </>
            )
        }
    }

    function getResultMessageColor() {
        if (twikeyResult === undefined) {
            return "greyPaper mt-2 mb-2"
        }
        else if (twikeyResult.state === TwikeyState.Paid) {
            return "greenPaper mt-2 mb-2"
        }
        else if (twikeyResult.state === TwikeyState.Declined || twikeyResult.state === TwikeyState.Expired) {
            return "redPaper mt-2 mb-2"
        } else {
            return "greyPaper mt-2 mb-2"
        }
    }

    function getResultMessage() {
        if (twikeyResult != null) {
            if (twikeyResult.state === TwikeyState.Paid)
                return (<>{t('afterpayment.paid')}</>)
            if (twikeyResult.state === TwikeyState.Declined)
                return (<>{t('afterpayment.declined')}</>)
            if (twikeyResult.state === TwikeyState.Expired)
                return (<>{t('afterpayment.expired')}</>)
            if (twikeyResult.state === TwikeyState.Pending)
                return (<>{t('afterpayment.pending')}</>)
            if (twikeyResult.state === TwikeyState.Created)
                return (<>{t('afterpayment.created')}</>)
            if (twikeyResult.state === TwikeyState.Started)
                return (<>{t('afterpayment.started')}</>)
        } else {
            if (!resultReceivedFromTwikey) {
                return (<>{t('afterpayment.twikeywaiting')}</>)
            } else {
                return (<>{t('afterpayment.twikeytimedout')}</>)
            }
        }
    }

    function loadTwikeyResult() {
        if (!resultReceivedFromTwikey) {
            return (
                <>
                    {PollTwikeyForResult()}
                </>
            )
        }
    }


    function PollTwikeyForResult() {
        return (
            <Counter
                pollingFunction={checkTwikeyIfPaymentSucceed}
                stopFunction={showErrorMessage}
                errorMessage={t('afterpayment.twikeytimedout')}
            />
        )
    }

    function showErrorMessage() {
        setResultReceivedFromTwikey(true);
    }

    async function checkTwikeyIfPaymentSucceed() {
        // console.log("checkTwikeyIfPaymentSucceed");
        // console.log("twikeyId");
        // console.log(twikeyId);

        if (twikeyId === "") {
            await getTwikeyId();
        }

        if (twikeyId !== undefined) {

            let model = {
                twikeyId: twikeyId,
                planningItemDetailId: planningItemDetailId
            } as TwikeyPaymentLinkStatusRequest;

            paymentService.getPaymentLinkStatus(model).then((result) => {
                // console.log("Twikey paymentlinkstatus result");
                // console.log(result);
                // console.log(result.state);

                if (result != null) {
                    if (result.state === TwikeyState.Paid || result.state === TwikeyState.Declined || result.state === TwikeyState.Expired) {
                        //answer received
                        setTwikeyResult(result);
                        setResultReceivedFromTwikey(true);
                    }
                    else {
                        //answer not yet received
                        console.log("answer not yet received")
                    }
                }
            }).catch((error) => {
                toast.error(t('afterpayment.twikeyError'));
            }).finally(() => {
            });
        }
    }

    async function getTwikeyId() {
        let model = {
            planningItemDetailId: planningItemDetailId
        } as TwikeyIdRequest;

        paymentService.getTwikeyId(model).then((result) => {
            if (result !== null) {
                //success
                setTwikeyId(result);
            }

        }).catch((error) => {
            toast.error(t('afterpayment.twikeyIdError'));
        }).finally(() => {
        });
    }

    function testmode() {
        if (testMode) {
            return (
                <Box sx={{
                    flexGrow: 1,
                    margin: 1
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <p>PlanningItem Id: {planningItemDetailId}</p>
                            <p>Language code: {lan}</p>
                            <p>Twikey Id: {twikeyId}</p>
                            {getResultMessage()}
                        </Grid>
                    </Grid >
                </Box>
            );
        }
    }

    function getPlanningItemDetailIsActive() {
        if (planningItemDetailId) {
            planningService.IsPlanningItemDetailActive(planningItemDetailId).then((result) => {
                // console.log("IsPlanningItemDetailActive");
                // console.log(result);

                setPlanningItemDetailIsActive(result);

            }).catch((error) => {
                toast.error(t('afterpayment.planningError'));
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    function getCanChangeReservation() {
        if (planningItemDetailId) {
            planningService.CanChangeReservation(planningItemDetailId).then((result) => {
                if (result) {
                    setCanChangeReservation(result);
                }
            }).catch((error) => {
                toast.error(t('afterpayment.planningError'));
            }).finally(() => {
            });
        }
    }

    function changeReservationButton() {
        if (twikeyResult?.state === TwikeyState.Paid && canChangeReservation) {
            return (
                <div className='p-2'>
                    <Button className='' variant="contained" onClick={() => changeReservation()}>{t('afterpayment.change')}</Button>
                </div>
            )
        }
    }

    function changeReservation() {
        //console.log("ChangeReservation");
        setLoading(true);

        let state = {
            state: {
                replanPlanningItemDetailId: planningItemDetailId
            }
        }

        navigate("/SelectTimeslot", state);
    }

    function messageConfirmationMail() {
        if (twikeyResult?.state === TwikeyState.Paid) {

            return (
                <>
                    <div className='p-3'>
                        <h5>
                            {t('afterpayment.confirmationMail')}
                        </h5>

                        {t('afterpayment.closePage')}
                    </div>
                </>
            )
        }
    }

    function retryPaymentButton() {
        if (twikeyResult?.state === TwikeyState.Declined || twikeyResult?.state === TwikeyState.Expired) {
            return (
                <>
                    <Button
                        color="success"
                        className='m-2'
                        variant="contained"
                        onClick={() => GoToPayment()}
                        sx={{ fontSize: 25 }}>{t('afterpayment.payAgain')}</Button>
                </>
            )
        }
    }

    function GoToPayment() {
        if (planningItemDetailId) {
            let state = {
                state: {
                    planningItemDetailId: planningItemDetailId
                }
            }
            // console.log("state");
            // console.log(state);            
            navigate("/Payment", state);
        }
    }
}