import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, Button, Card, CardActions, CardContent, Divider, FormControl, InputLabel, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import { Header } from '../../Components/Header';
import PlanningService from '../../Services/PlanningService';
import { PlanningModel } from '../../Models/Planning/PlanningModel';
import { GetPlanningModel } from '../../Models/Planning/GetPlanningModel';
import { InitMedicalcenterColors, formatDate, formatTime } from '../../Components/Helpers';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ResourceModel } from '../../Models/Planning/ResourceModel';
import locale from 'dayjs/locale/nl-be';
import { DriverModel } from '../../Models/DriverCertificate/DriverModel';
import LoadingScreen from '../../Components/Loadingscreen';
import AddIcon from '@mui/icons-material/Add';

export default function SelectTimeslot() {
    dayjs.locale(locale);
    const { t } = useTranslation('common');
    const location = useLocation();
    const navigate = useNavigate();
    const today = dayjs();
    const defaultPlanningItemsLimit = 18;
    const defaultPlanningDays = 7;

    //state
    const states = location.state as any;
    const driverModel = states?.driverModel as DriverModel;
    const planningItemDetailId = states.planningItemDetailId as string;
    const replanPlanningItemDetailId = states.replanPlanningItemDetailId as string;

    //models
    const planningService = useMemo(() => new PlanningService(), []);
    const [planningModels, setPlanningModels] = useState<PlanningModel[]>();
    const [fromDate, setFromDate] = React.useState<Dayjs | null>(today);
    const [toDate, setToDate] = React.useState<Dayjs | null>(dayjs(today.add(defaultPlanningDays, 'day')));
    const [getPlanningModel, setGetPlanningModel] = useState<GetPlanningModel>({
        fromDate: fromDate,
        toDate: toDate,
        medicalCenterId: "0"
    } as GetPlanningModel);
    const [loadingPlanning, setLoadingPlanning] = useState<boolean>(false);
    const [loadingResources, setLoadingResources] = useState<boolean>(false);
    const [resources, setResources] = useState<ResourceModel[]>();
    const [planningLimit, setPlanningLimit] = useState<number>(defaultPlanningItemsLimit);

    useEffect(() => {
        // console.log("planningItemDetailId");
        // console.log(planningItemDetailId);

        if (planningItemDetailId) { //wanneer planning is gemaakt via Colibri en persoonsgegevens moeten aangevuld worden. (via CompleteAppointment.tsx)
            let state = {
                state: {
                    driverModel: driverModel,
                    planningItemDetailId: planningItemDetailId
                }
            }
            navigate("/Summary", state);
        } else { //Default flow ... select timeslot
            InitResources();
        }
    }, []);

    useEffect(() => {
        if (planningItemDetailId == null) {
            GetTimeSlots();
        }
    }, [getPlanningModel]);

    if (planningItemDetailId == null)
        return (
            <>
                {getHeader()}
                <div className='py-2 background-color-lightgray-radial'>
                    {GetResources()}
                    {GetDatePicker()}
                </div>
                <h3 className='py-2'>
                    {t('planner.plannertitle')}
                </h3>
                <div>
                    {GetPlanningCards()}
                    {GetShowModeButton()}
                </div>
            </>
        )
    else {
        return (<LoadingScreen />)
    }

    function InitResources() {
        setLoadingResources(true);
        planningService.GetResourcesMedicalCenter().then((result) => {
            setLoadingResources(false);
            setResources(result);
        }).catch((error) => {
            toast.error(t('planner.noreourcesfounderror'));
        }).finally(() => {
        });
    }

    function getHeader() {
        if (driverModel) {
            return (
                <Header description={t('planner.header') + " " + driverModel?.firstName + " " + driverModel?.lastName} />
            )
        } else {
            return (
                <Header description='' />
            )
        }
    }

    function GetTimeSlots() {
        setLoadingPlanning(true);
        setPlanningModels([]);

        //Reset the planning limiter to default 
        setPlanningLimit(defaultPlanningItemsLimit);

        planningService.GetPlanning(getPlanningModel).then((result) => {
            //console.log(result);

            //init colours            
            setPlanningModels(InitMedicalcenterColors(result));

        }).catch((error) => {
            toast.error(t('planner.noslotsfounderror'));
        }).finally(() => {
            setLoadingPlanning(false);
        });
    }

    function GetPlanningCards() {
        if (loadingPlanning) {
            return (
                <LoadingScreen />
            )
        }

        if (planningModels !== undefined && planningModels?.length > 0) {

            const dayNames = [t('dayNames.sunday'), t('dayNames.monday'), t('dayNames.tuesday'), t('dayNames.wednesday'), t('dayNames.thursday'), t('dayNames.friday'), t('dayNames.saturday')];

            let sorted = planningModels?.sort((a, b) => a.start > b.start ? 1 : -1);

            let mapped = sorted.map((model: PlanningModel, index) => {
                return (
                    index < planningLimit &&
                    <Box component="span" key={model.planningItemId + model.start + model.end}
                        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}>
                        <Card sx={{
                            width: 350, height: 300, display: "flex", flexDirection: "column",
                        }} className='makeTransparent'                        >
                            <CardContent>
                                <Tooltip title={model.medicalCenter.street + ' ' + model.medicalCenter.houseNumber + '\n' + model.medicalCenter.postalcode + ' ' + model.medicalCenter.city}>
                                    <Typography sx={{ fontSize: 25 }}
                                        color="text.secondary">
                                        {model.medicalCenter.name}
                                    </Typography>
                                </Tooltip>
                                <Divider className='m-1'
                                    // color={model.medicalCenter.color}
                                    sx={{ height: 5, opacity: 70 }}
                                />
                                <Typography color="text.secondary"
                                    sx={{ fontSize: 22 }}>
                                    {dayNames[new Date(model.start).getDay()]} {formatDate(model.start)}
                                </Typography>
                                <Typography variant="h3" component="div">
                                    {formatTime(model.start)}
                                </Typography>
                                <Typography variant="h6" component="div">
                                    ({model.examinationDuration} {t('planner.minutes')})
                                </Typography>
                            </CardContent>
                            <CardActions
                                className='d-flex justify-content-center mb-2'
                                disableSpacing sx={{ mt: "auto" }}
                            >
                                <Button
                                    variant="contained"
                                    key={model.start + '_' + model.planningItemId}
                                    size="medium"
                                    onClick={() => SelectSlot(model)}>
                                    {t('planner.reserve')}</Button>
                            </CardActions>
                        </Card>
                    </Box >
                )
            });

            return mapped;

        } else {
            return (<h2>{t('planner.noslotsfound')}</h2>)
        }
    }

    function SelectSlot(model: PlanningModel) {
        let state = {
            state: {
                driverModel: driverModel,
                planningModel: model,
                replanPlanningItemDetailId: replanPlanningItemDetailId
            }
        }

        navigate("/Summary", state);
    }

    function GetDatePicker() {
        return (
            <>
                <FormControl sx={{ m: 1, minWidth: 260 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            minDate={today}
                            inputFormat="DD/MM/YYYY"
                            label={t('planner.from')}
                            value={fromDate}
                            // value={null}
                            onChange={(newValue) => {
                                //console.log(newValue?.toDate());

                                let adjustedToDate: dayjs.Dayjs | null = null;
                                if (newValue && toDate) {
                                    if (newValue > toDate) {
                                        // console.log("Adjust toDate");
                                        adjustedToDate = dayjs(newValue.add(defaultPlanningDays, 'day'));
                                        setToDate(adjustedToDate);
                                    }
                                }

                                setFromDate(newValue);

                                setGetPlanningModel(prevFile => {
                                    const copy = { ...prevFile } as GetPlanningModel;
                                    copy.fromDate = newValue !== null ? newValue?.toDate() : null;

                                    if (adjustedToDate !== null) {
                                        //Also fill in toDate on model when adjusted for correct recalculation
                                        copy.toDate = adjustedToDate !== null ? adjustedToDate?.toDate() : null;
                                    }

                                    return copy;
                                });


                            }}
                            renderInput={(params) => <TextField {...params}
                            />}
                        />
                    </LocalizationProvider>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 260 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            minDate={fromDate}
                            inputFormat="DD/MM/YYYY"
                            label={t('planner.to')}
                            // value={date}
                            value={toDate}
                            onChange={(newValue) => {
                                //console.log(newValue?.toDate());

                                setToDate(newValue);
                                setGetPlanningModel(prevFile => {
                                    const copy = { ...prevFile } as GetPlanningModel;
                                    copy.toDate = newValue !== null ? newValue?.toDate() : null;
                                    return copy;
                                });
                            }}
                            renderInput={(params) => <TextField {...params}
                            />}
                        />
                    </LocalizationProvider >
                </FormControl >
            </>
        );
    }

    function GetResources() {
        return (
            <>
                <FormControl sx={{ m: 1, minWidth: 260 }}>
                    <InputLabel id="demo-simple-select-label">{t('planner.location')}</InputLabel>
                    <Select
                        autoWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={getPlanningModel.medicalCenterId}
                        label={t('planner.location')}
                        onChange={(event) => {
                            setGetPlanningModel(prevFile => {
                                const copy = { ...prevFile } as GetPlanningModel;
                                copy.medicalCenterId = event.target.value;
                                return copy;
                            });
                        }}
                    >
                        <MenuItem value="0"><em>{t('planner.all')}</em></MenuItem>
                        {GetLocations()}
                    </Select>
                </FormControl>
            </>
        );
    }

    function GetLocations() {
        if (loadingResources) {
            return (
                <LoadingScreen />
            )
        }

        if (resources !== undefined && resources.length > 0) {
            let sort = resources?.sort((a, b) => a.name > b.name ? 1 : -1);
            let mapped = sort.map((model: ResourceModel) => {
                return (
                    <MenuItem key={model.id} value={model.id}>{model.name}</MenuItem>
                )
            })
            return mapped;
        }
    }

    function GetShowModeButton() {
        if (planningModels !== undefined && planningLimit < planningModels?.length)
            return (
                <>
                    <div className='p-3'>
                        <Button variant="contained" onClick={() => {
                            setPlanningLimit(planningLimit + defaultPlanningItemsLimit);
                        }}><AddIcon /> {t('planner.loadMore')}</Button >
                    </div>
                </>
            )
    }
}